
jQuery( document ).ready( function() {

	// Side-menu level 1 show/hide
	// jQuery( '#sidebar-menu span.dropdown' ).on( 'click',  function( e ) {
  //       jQuery( this ).next( 'ul' ).toggle();
  //       jQuery( this ).next( 'ul' ).toggleClass( 'open' );
  //       jQuery( this ).toggleClass( 'open' );
  //   });

  // Side-menu level 2 show/hide active
	// jQuery( '#sidebar-menu .submenu_level_1 span.dropdown' ).on( 'click',  function( e ) {
  //       jQuery( this ).next( 'ul' ).toggle();
  //       jQuery( this ).next( 'ul' ).toggleClass( 'open' );
  //       jQuery( this ).toggleClass( 'open' );
  //   });

  // Side-menu level 2 show/hide active
	jQuery( '#sidebar-menu .submenu_level_1 span.dropdown' ).on( 'click',  function( e ) {
      if( jQuery( this ).siblings('.sub-menu').children('.current-menu-item').length == 0) {
        jQuery( this ).next( 'ul' ).toggle();
        jQuery( this ).next( 'ul' ).toggleClass( 'open' );
        jQuery( this ).toggleClass( 'open' );
     };

    });

  if(jQuery( '#sidebar-menu .submenu_level_1 .sub-menu .current-menu-item' ).length >= 1) {
    jQuery( '#sidebar-menu .submenu_level_1 .sub-menu .current-menu-item' ).closest('.submenu_level_2').siblings('.dropdown').toggleClass('open');
    jQuery( '#sidebar-menu .submenu_level_1 .sub-menu .current-menu-item' ).closest('.submenu_level_2').toggleClass('open');
  }


	var mediaWatcher = window.matchMedia( "(max-width: 1024px)" );

	jQuery( '.menu-item-level-0-auto > a' ).on('click',function(e) {
		if ( e.target != this ) {
			return;
		}
		if ( mediaWatcher.matches ) {
			jQuery( this ).next().toggle();
		} else {
			return;
		}
	});

	jQuery( '.sidebar-meny-bedrift .menu-item-level-0' ).on('click',function(e) {
		/*if ( e.target != this ) {
			return;
		}*/
		if ( mediaWatcher.matches ) {
			jQuery( this ).children( 'ul' ).toggle();
		} else {
			return;
		}
	});
});
